<template>
  <v-sheet>
    <v-menu
        bottom
        left
        min-width="200"
        offset-y
        origin="bottom-left"
        transition="scale-transition"
        v-if="sortie.sortieStatus !== 'complete' && canUseActionMenu"
    >
      <template #activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <v-btn
                icon
                v-on="{ ...onMenu, ...onTooltip }">
              <v-icon size="25">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <span>Actions</span>
        </v-tooltip>
      </template>

      <v-sheet rounded>
        <v-list
            dense
            :tile="false"
            nav
            v-if="sortie.sortieStatus !== 'complete' && canUseActionMenu">
          <v-list-item>
            <v-list-item-content>
              <div class="text-h6 font-weight-bold">Flight strip actions</div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item-group v-for="(action,i) in actionsList" :key="i">
            <v-list-item two-line @click="openModal(action.modal,sortie)"
                         v-if="sortie.availableActions.includes(action.action)"
                         :disabled="!scopedCanFly && action.canFlyStatusApplied">
              <v-list-item-icon>
                <v-icon>{{ action.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ action.title }} {{ action.canFlyStatusApplied ? scopedCanFly ? '' : '(disabled)' : '' }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="!scopedCanFly && action.canFlyStatusApplied">Check notification
                  bell
                </v-list-item-subtitle>
                <!--                <v-list-item-subtitle v-text="action.subtitle"/>-->
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-menu>
    <div v-else>
      <v-list-item @click="openModal('ViewSortieModal',sortie)">
        <v-list-item-icon>
          <v-icon>mdi-eye</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            View
          </v-list-item-title>
          <!--        <v-list-item-subtitle v-text="action.subtitle"/>-->
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "FlightStripActions",

  props: {
    loading: {
      type: Boolean
    },
    sortie: {
      type: Object
    }
  },
  data() {
    return {
      cancelSortieDialog: false,
      actionsList: [
        {
          icon: "mdi-eye",
          title: "View",
          subtitle: "View more information about this flight",
          action: "view",
          modal: "ViewSortieModal",
          canFlyStatusApplied: false
        },
        {
          icon: "mdi-pencil",
          title: "Edit",
          subtitle: "Edit information about this flight",
          action: "edit",
          modal: "EditSortie",
          canFlyStatusApplied: false
        },
        {
          icon: "mdi-exit-run",
          title: "Walk Out",
          subtitle: "Notify duty personnel that this flight is about to commence.",
          action: "walk out",
          modal: "WalkOutModal",
          canFlyStatusApplied: true
        },
        {
          icon: "mdi-home-import-outline",
          title: "Book In",
          subtitle: "Notify duty personnel the flight is completed and provide a record of Time, Landings and Notes.",
          action: "book in",
          modal: "BookInModal",
          canFlyStatusApplied: false
        },
        {
          icon: "mdi-cancel",
          title: "Cancel",
          subtitle: "Notify that this flight is cancelled and does not need to be monitored",
          action: "cancel",
          modal: "CancelSortieModal",
          canFlyStatusApplied: false
        },
        {
          icon: "mdi-delete-restore",
          title: "Recover",
          subtitle: "Recover this Cancelled flight",
          action: "recover",
          modal: "RecoverSortieModal",
          canFlyStatusApplied: true
        },
        {
          icon: "mdi-delete-forever",
          title: "Delete",
          subtitle: "Delete the flight from the Database (this cannot be undone)",
          action: "delete",
          modal: "DeleteSortieModal",
          canFlyStatusApplied: false
        },

      ]
    }
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    ...mapState("user", ["accountRoles", "flyingStatus"]),

    scopedCanFly(){
      let group = this.flyingStatus.find(g => g.groupId == this.sortie.group)
      return group.canFly;
    },
    isSortieManager() {
      const userRoles = this.accountRoles.map(g => g.name)
      return userRoles.includes('rootAdmin') || userRoles.includes('manageSorties')
    },
    sortieCrewMemberIds() {
      return this.sortie.crewList.map(c => c.personId)
    },
    canUseActionMenu() {
      return this.sortie.owner === this.loggedInUser._id ||  this.isSortieManager || this.sortieCrewMemberIds.includes(this.loggedInUser._id)
    }
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("sorties", ["setActiveSortie", "setSorties", "setManageWalkOutDialog", "setManageRecoverSortieDialog", "setManageCancelSortieDialog", "setManageDeleteSortieDialog", "setManageSortieBookInDialog", "setManageViewSortieDialog"]),

    async openModal(modal, sortie) {
      this.setActiveSortie(sortie)
      switch (modal) {
        case "WalkOutModal":
          await this.setManageWalkOutDialog(true)
          break;
        case "BookInModal":
          await this.setManageSortieBookInDialog(true)
          break;
        case "RecoverSortieModal":
          await this.setManageRecoverSortieDialog(true)
          break;
        case "CancelSortieModal":
          await this.setManageCancelSortieDialog(true)
          break;
        case "DeleteSortieModal":
          await this.setManageDeleteSortieDialog(true)
          break;
        case "ViewSortieModal":
          await this.setManageViewSortieDialog(true)
          break;
        case "EditSortie":
          await this.$router.push(`/sorties/entry/${sortie._id}`)
          break;
      }
    },
  }
}
</script>

<style scoped>

</style>