<template>
  <div>
    <v-data-table :items="items" :headers="headers" :loading="loading" dense multi-sort
                  :sort-by="['eTD.utcTimestamp', 'departure']"
                  :sort-desc="[false, false]"
                  :items-per-page="-1"
                  :mobile-breakpoint="1264">
      <template v-slot:item.icon="{ item }">
        <v-icon v-if="item.sortieStatus == 'late'" color="red">mdi-alert</v-icon>
      </template>
      <template v-slot:item.group="{ item }">
        <v-avatar color="primary" size="38">
          <span class="white--text text-body-2">{{ getGroupName(item.group,'abbreviation') }}</span>
        </v-avatar>
      </template>

      <template v-slot:item.registration="{ item }">
        <div class="text-body-2">{{ item.registration }}</div>
        <div class="text-caption">{{ item.tailNumber }}</div>
      </template>
      <template v-slot:item.date="{ item}">
        <div class="text-body-2">
          {{ formatDate(item.date, 'dd MMM') }}
        </div>
      </template>
      <template v-slot:item.departure="{ item }">
        <div class="text-body-2">{{
            item.sortieStatus === 'complete' ? formatTimestamp(item.aTD.localTimestamp, 'HH:mm') : formatTimestamp(item.eTD.localTimestamp, 'HH:mm')
          }}&nbsp;{{ item.sortieStatus === 'complete' ? item.aTD.abbreviation : item.eTD.abbreviation }}
        </div>
        <div class="text-body-2">
          {{
            item.sortieStatus === 'complete' ? formatTimestamp(item.aTD.utcTimestamp, 'HH:mm') : formatTimestamp(item.eTD.utcTimestamp, 'HH:mm')
          }}&nbsp;Z
        </div>
      </template>
      <template v-slot:item.arrival="{ item }">
        <div v-if="item.hasWalked">
          <div class="text-body-2">{{ checkIsSameDay(item.date) ? '' : formatDate(item.date, 'dd MMM') }}
          </div>
          <div class="text-body-2">{{
              item.sortieStatus === 'complete' ? formatTimestamp(item.aTA.localTimestamp, 'HH:mm') : formatTimestamp(item.eTA.localTimestamp, 'HH:mm')
            }}&nbsp;{{ item.sortieStatus === 'complete' ? item.aTD.abbreviation : item.eTA.abbreviation }}
          </div>
          <div class="text-body-2">{{
              item.sortieStatus === 'complete' ? formatTimestamp(item.aTA.utcTimestamp, 'HH:mm') : formatTimestamp(item.eTA.utcTimestamp, 'HH:mm')
            }}&nbsp;Z
          </div>
        </div>
      </template>
      <!--      <template v-slot:item.eET="{ item }">-->
      <!--        <div>-->
      <!--          <div class="text-body-2">{{ item.eETFormatted }}</div>-->
      <!--        </div>-->
      <!--      </template>-->
      <template v-slot:item.crewList="{ item }">
        <div>
<!--          <div class="text-body-2" v-for="(crew,i) in item.crewList" :key="i">-->
          <v-tooltip bottom v-for="(crew,i) in item.crewList" :key="i">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar color="primary" v-bind="attrs" v-on="on" size="38">
                <span class="white--text text-body-2">{{getFirstLetters(crew.person)}}</span>
              </v-avatar>
            </template>
            <span>{{ crew.person }}</span>
          </v-tooltip>
<!--              </div>-->
        </div>
      </template>
      <template v-slot:item.sortieStatus="{ item }">
        <flight-strip-status-chip :status="item.sortieStatus"/>
      </template>
      <template v-slot:item.actions="{ item }">
        <flight-strip-actions :loading="loading" :sortie="item"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FlightStripActions from "@/components/shared/FlightStripActions";
import FlightStripStatusChip from "@/components/shared/FlightStripStatusChip";
import {format, fromUnixTime, isSameDay} from "date-fns";
import {getGroupName} from "@/util/helpers";
import {mapState} from "vuex";

export default {
  name: "FlightStripSection",
  components: {FlightStripStatusChip, FlightStripActions},
  // components: {FlightStrip},
  props: {
    flightStrips: {
      type: Array
    },
    title: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    filterComplete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: [
        {
          text: '',
          align: '',
          sortable: false,
          value: 'icon'
        },
        {
          text: 'Group',
          align: '',
          value: 'group'
        },
        {
          text: 'Registration',
          align: 'start',
          sortable: false,
          value: 'registration'
        },
        {text: 'Departure Date', value: 'date'},
        {text: 'Departure Time', value: 'departure'},
        {text: 'Arrival', value: 'arrival'},
        // {text: 'Enroute Time.', value: 'eET'},
        {text: 'Crew', value: 'crewList'},
        {text: 'Flight Status', value: 'sortieStatus'},
        {text: 'Actions', value: 'actions'}
      ]
    }
  },
  computed: {
    ...mapState("group", ["groups"]),
    items(){
      return this.filterComplete ? this.flightStrips.filter(s=>s.sortieStatus !== 'complete') : this.flightStrips
    }
  },
  methods: {
    getGroupName(id,style){
      return getGroupName(this.groups,id,style)
    },
    formatTimestamp(timestamp, formatToReturn) {
      return format(fromUnixTime(timestamp), formatToReturn)
    },
    formatDate(timestamp, formatToReturn) {
      return format(new Date(timestamp), formatToReturn)
    },
    checkIsSameDay(date){
      return isSameDay(new Date(), new Date(date))
    },
    getFirstLetters(str) {
      const firstLetters = str
          .split(' ')
          .map(word => word[0])
          .join('');

      return firstLetters;
    }
  }
}
</script>

<style scoped>
@keyframes blinking {
  0% {
    border-left: 4px solid tomato;
  }

  50% {
    background-color: tomato;
    border-left: 4px solid tomato;
  }

  100% {
    border-left: 4px solid tomato;
  }
}

.flight-strip.late {
  border-left: 4px solid tomato;
  animation: blinking 6s infinite;
}

.flight-strip.complete {
  border-left: 4px solid mediumpurple;
}

.flight-strip.awaiting.flight.details {
  border-left: 4px solid orange;
}

.flight-strip.airborne {
  border-left: 4px solid blue;
}

.flight-strip.walking.to.aircraft {
  border-left: 4px solid blue;
}

.flight-strip.start.up.and.taxi {
  border-left: 4px solid blue;
}

.flight-strip.delayed.departure {
  border-left: 4px solid mediumorchid;
}

.flight-strip.scheduled {
  border-left: 4px solid orange;
}
</style>