<template>
  <div>
    <v-sheet rounded="lg" class="background text-h4 font-weight-light text-center primary my-2" min-height="60px">
      <v-container>
        <v-row>
          <v-col cols="12" md="12">Flying Programme</v-col>
          <v-spacer/>
          <v-col cols="2" md="2"><app-btn @click="showFilter=!showFilter"><v-icon>{{ showFilter ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>Filters and Search</app-btn></v-col>
        </v-row>
        </v-container>
    </v-sheet>
      <v-sheet rounded="lg" class="background text-h4 font-weight-light text-left primary my-2" min-height="60px" v-show="showFilter">
      <v-container>
        <v-row>
          <v-col cols="12" md="12" lg="12">
            <div class="text-caption">Search for flights from the following group(s)</div>
            <div class="text-body-2">
              <v-chip-group
                  v-model="localSearchGroupIds"
                  active-class="secondary--text text--accent-4"
                  column
                  multiple
                  @change="changeLocalSearchGroupIds"
              >
                <v-chip v-for="(c) in scopedGroups" :key="c._id" :value="c._id" filter
                        color="primary" active-class="white--text"
                        size="30" text-color="white">
                  {{ getGroupName(c._id,'abbreviation') }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-col>
          <v-col cols="10" md="3">
            <div class="text-caption" v-if="$vuetify.breakpoint.mdAndUp">&nbsp;</div>
            <div class="text-body-2">
              <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="computedLocalSearchStartDate"
                      label="Earliest Sortie Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="localSearchStartDate"
                    @input="changeLocalSearchStartDate"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" md="1">
            <div class="text-caption" v-if="$vuetify.breakpoint.mdAndUp">&nbsp;</div>
            <div class="text-body-2 align-center">to</div>
          </v-col>
          <v-col cols="10" md="3">
            <div class="text-caption" v-if="$vuetify.breakpoint.mdAndUp">&nbsp;</div>
            <div class="text-body-2">
              <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="computedLocalSearchEndDate"
                      label="Latest Sortie Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="localSearchEndDate"
                    @input="changeLocalSearchEndDate"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <div class="text-caption">
              Hide completed?
            </div>
            <div>
              <v-switch v-model="filterCompletedFlights" :label="filterCompletedFlights? 'On':'Off'"/>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="text-caption" v-if="$vuetify.breakpoint.mdAndUp">&nbsp;</div>
            <div>
            <v-btn
                color="primary"
                @click="search"
            >
              <v-icon left>mdi-magnify</v-icon>
              <span>Search</span>
            </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>

    </v-sheet>

    <v-sheet>
      <v-tabs
          v-model="tab"
          class="background mb-3"
          grow
          show-arrows
          :vertical="$vuetify.breakpoint.smAndDown ? true : false">
        <v-tab
            v-for="item in menuItems"
            :key="item.name"
        >
          <v-icon>{{ item.icon }}</v-icon>
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" elevation="0">
        <v-tab-item eager key="Table">
          <v-sheet rounded="lg" class="mb-2 mx-2 surface" v-for="(section,i) in flightStripBay1" :key="i">
            <flight-strip-section :title="section.title" :flight-strips="section.flightStrips" :loading="loading" :filter-complete="filterCompletedFlights"
                                  class="surface"/>
          </v-sheet>
        </v-tab-item>
        <v-tab-item eager key="Calendar">
          <flight-calendar :events="flightEvents" :selected-date="selectedDate"/>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
    <v-sheet>
      Last Refreshed {{ lastUpdated }}
    </v-sheet>
  </div>
</template>

<script>
// import FlightStripBay from "@/components/shared/FlightStripBay";
import {mapState} from "vuex";
import FlightStripSection from "@/components/shared/FlightStripSection";
import FlightCalendar from "@/components/shared/FlightCalendar";
import {format, parseISO} from "date-fns";
import {getListOfAvailableSortieActions, getSortieStatus} from "@/util/sortie";
import {getGroupName} from "@/util/helpers";
import AppBtn from "@/components/app/Btn";

export default {
  name: "IntegratedWorkingPosition",
  components: {AppBtn, FlightCalendar, FlightStripSection},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    lastUpdated: {
      type: Date,
    },
    searchGroupIds: {
      type: Array
    },
    searchStartDate: {
      type: String
    },
    searchEndDate: {
      type: String
    },
    scopedGroups: {
      type: Array
    }
  },
  data() {
    return {
      tab: null,
      menuItems: [{icon: "mdi-table", name: "Table"}, {icon: "mdi-calendar", name: "Calendar"}],
      localSearchGroupIds: this.searchGroupIds,
      localSearchStartDate: this.searchStartDate,
      localSearchEndDate: this.searchEndDate,
      startDate: '',
      menuStartDate: false,
      endDate: '',
      menuEndDate: false,
      selectedDate: '',
      filterCompletedFlights:false,
      showFilter:false
    }
  },
  computed: {
    ...mapState("sorties", ["sorties","availableActions"]),
    ...mapState("group", ["groups"]),



    computedLocalSearchStartDate() {
      return this.localSearchStartDate ? format(parseISO(this.localSearchStartDate), 'dd MMM yyyy') : ''
    },
    computedLocalSearchEndDate(){
      return this.localSearchEndDate ? format(parseISO(this.localSearchEndDate), 'dd MMM yyyy') : ''
    },

    sortieStrips() {
      return this.sorties? this.sorties.filter(s => s.sortieStatus !== "cancelled"):[]
    },

    sortieWithStatusAndAvailableActions() {
      return this.sortieStrips.map(s => ({ ...s, sortieStatus: getSortieStatus(s),
        availableActions:getListOfAvailableSortieActions(getSortieStatus(s),this.availableActions) }))
    },

    flightStripBay1() {
      return [{title: 'Flying Programme', flightStrips: this.sortieWithStatusAndAvailableActions}
      ]
    },
    flightEvents() {
      const events = []
      this.sortieStrips.forEach(s => {
        let localStart = s.eTD.localTimestamp * 1000
        let localEnd = localStart + (s.eET * 60 * 1000)

        let crewListString = s.crewList.map(c => this.getFirstLetters(c.person))

        events.push({
          name: `${s.registration} - ${s.pointOfDepartureICAO} - ${s.pointOfArrivalICAO}`,
          start: localStart,
          end: localEnd,
          eventDetails: `${crewListString} | ${s.route}`,
          timed: true
        })
      })
      return events
    }
  },
  methods: {
    getFirstLetters(str) {
      const firstLetters = str
          .split(' ')
          .map(word => word[0])
          .join('');

      return firstLetters;
    },
    getGroupName(id,style){
      return getGroupName(this.groups,id,style)
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    search() {
      this.$emit("search")
    },
    changeLocalSearchStartDate(date) {
      this.$emit("changeSearchStartDate", date)
      this.menuStartDate = false
    },
    changeLocalSearchEndDate(date) {
      this.$emit("changeSearchEndDate", date)
      this.menuStartDate = false
    },
    changeLocalSearchGroupIds(groupIdsArray) {
      this.$emit("changeSearchGroupIds", groupIdsArray)
    }

  },
  watch: {
    searchGroupIds(newValue) {
      this.localSearchGroupIds = newValue
    },
    searchStartDate(newValue) {
      this.localSearchStartDate = newValue
    },
    searchEndDate(newValue) {
      this.localSearchEndDate = newValue
    },
  }
}
</script>

<style scoped>

</style>