<template>
  <div>
    <v-sheet height="64">
      <v-toolbar
          flat
          dense
      >


      </v-toolbar>
    </v-sheet>
    <v-sheet height="64">
      <v-toolbar
          flat
          dense
      >
        <v-btn @click="setToday" small >
          <span class="hidden-sm-and-down">Today</span>

          <v-icon right>
            mdi-calendar-today
          </v-icon>
        </v-btn>

        <v-btn @click="prev" small >
          <v-icon>
            mdi-chevron-left
          </v-icon>
<!--          <span class="hidden-sm-and-down">previous</span>-->

        </v-btn>

        <v-btn @click="next" small >
<!--          <span class="hidden-sm-and-down">next</span>-->

          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
        <v-btn-toggle
            v-model="type"
            borderless
            mandatory
        >
          <v-btn value="day" small>
            <span class="hidden-sm-and-down">Day</span>

            <v-icon right>
              mdi-calendar
            </v-icon>
          </v-btn>

          <v-btn value="week"  small >
            <span class="hidden-sm-and-down">Week</span>

            <v-icon right>
              mdi-calendar-week
            </v-icon>
          </v-btn>

          <v-btn value="month" small>
            <span class="hidden-sm-and-down">Month</span>

            <v-icon right>
              mdi-calendar-month
            </v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-divider vertical></v-divider>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar :type="type" ref="calendar" :events="items" is-expanded
                  :first-interval="calendarDayStartTimeMinutes/calendarDayIntervalMinutes"
                  :interval-minutes="calendarDayIntervalMinutes"
                  :interval-count="calendarNumberOfIntervals"
                  :value="selectedDate" :focus="selectedDate" @input="changeSelectedDate($event)"
                  :weekdays="weekday"
                  :event-overlap-mode="mode" :event-overlap-threshold="30"
                  category-show-all
                  :categories="categories"
                  category-text="name"
                  @change="updateRange"
                  @click:date="viewDay"
                  @click:more="viewDay"
                  @click:event="showEvent"
                  @click:time="createEvent"
      >
        <template v-slot:event="{ event }">
          <v-container>
            <v-row dense>
              <v-col cols="12" class="mt-n3">
                <div class="caption"><span v-if=" event.startTime !== event.endTime">{{
                    event.startTime
                  }} - {{ event.endTime }}</span><span v-else>{{ event.startTime }}</span>
                  {{ event.name }}
                </div>
              </v-col>
              <v-col cols="12" class="mt-n3">
                <div class="caption ml-2">{{ event.category }}</div>
              </v-col>
              <v-col cols="12" class="mt-n3">
                <div class="caption">{{ event.eventDetails ? event.eventDetails : '' }}</div>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #category="{ category }">
          <div
              class="v-calendar-category__category text-truncate ml-1"
              :title="category"
          >
            {{ category }}
          </div>
        </template>
        <template v-slot:day-body="{ date, week }">
          <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
          ></div>
        </template>

      </v-calendar>
    </v-sheet>
  </div>

</template>

<script>

import {mapActions} from "vuex";
import moment from "moment/moment";

export default {
  name: "Calendar",
  props: {
    calendarDayStartTimeMinutes: {
      required: true,
    },
    calendarDayEndTimeMinutes: {
      required: true,
    },
    calendarDayIntervalMinutes:{
      required: true,
      type:Number,
      default:30
    },
    items: {
      required: true,
      type: Array,
      default: () => []
    },
    selectedDate: {
      required: true
    },
    categories: {},
    scopedGroups:{
      required: true,
      type: Array,
      default: () => []
    },
    weekday:{
      required: true,
      type: Array,
      default: () => [1, 2, 3, 4, 5, 6, 0]
    },
    mode:{
      required: true,
      type: String,
      default:"stack"
    }

  },
  data() {
    return {
      calRange: {start: '', end: ''},
      // mode: 'stack',
      // modes: ['stack', 'column'],
      // weekday: [1, 2, 3, 4, 5, 6, 0],
      ready: false,
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        category: 'Category',
        '4day': '4 Days',
      }
    }
  },
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    calendarNumberOfIntervals() {
      return (this.calendarDayEndTimeMinutes-this.calendarDayStartTimeMinutes)/this.calendarDayIntervalMinutes
    }
  },
  mounted() {
    this.ready = true
    this.setToday()
    this.scrollToTime()
    this.updateTime()
  },
  methods: {
    ...mapActions("calendar", ["setManageCalendarEventDialog", "setActiveCalendarEvent", "setCalendars"]),

    setToday() {
      let today = new Date().toISOString()
      this.changeSelectedDate(today)
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },

    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },

    refreshCalendar() {
      this.$emit("refreshCalendar")
      this.$refs.calendar.checkChange()
    },

    changeSelectedDate(event) {
      this.$emit("updateSelectedDate", event)
      this.$refs.calendar.checkChange()
    },

    toggleFilters() {
      this.$emit("toggleFilters")
    },
    async updateRange({start, end}) {
      let viewStart = this.$refs.calendar.getStartOfWeek(start);
      let viewEnd = this.$refs.calendar.getEndOfWeek(end);
      this.$emit("changeCalendarViewStartAndEndValues", {start: viewStart.date, end: viewEnd.date})
      this.$refs.calendar.checkChange()
      this.$refs.calendar.scrollToTime(moment().format('HH:mm'))
    },

    viewDay({date}) {
      // this.selectedViewableEvents = null
      this.changeSelectedDate(date)
      this.type = 'day'
    },

    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },

    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },

    //Event Related

    async createEvent(tms) {
      const mouse = this.toTime(tms)
      const eventStartDateTimeMs = moment(this.roundTime(this.toTime(tms)))
      // add default 1 hour slot. (could be extended to profile data
      const eventEndDateTimeMs = moment(eventStartDateTimeMs).add(1, 'hours')

      const startDate = eventStartDateTimeMs.format('yyyy-MM-DD')
      const startTime = eventStartDateTimeMs.format('HH:mm')
      const endDate = eventEndDateTimeMs.format('yyyy-MM-DD')
      const endTime = eventEndDateTimeMs.format('HH:mm')

      this.createStart = new Date(this.roundTime(mouse))
      await this.setActiveCalendarEvent({
        status: "confirmed",
        timed: true,
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
        tags: []
      })
      await this.setManageCalendarEventDialog(true)
    },

    async showEvent({nativeEvent, event}) {
      // await this.setLoading(true)
      await this.setActiveCalendarEvent(event)
      await this.setManageCalendarEventDialog(true)
      nativeEvent.stopPropagation()
      // await this.setLoading(false)
    },

  }
}
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>