import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{},[_c(VLayout,{attrs:{"row":"","wrap":"","align-content-center":""}},[_c(VFlex,{staticClass:"pa-2",attrs:{"xs8":""}},[_c(VSwitch,{attrs:{"label":_vm.autoUpdate ? `Update in ${_vm.formattedTimeLeft}` : 'Auto Update'},model:{value:(_vm.autoUpdate),callback:function ($$v) {_vm.autoUpdate=$$v},expression:"autoUpdate"}})],1),_c(VFlex,{staticClass:"pa-2",attrs:{"xs4":""}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('app-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"small":"","fab":"","color":"primary"},on:{"click":_vm.onTimesUp}},'app-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh now")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }