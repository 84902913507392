<template>
  <v-alert
      dense
      :type="roleHolder ? 'info':'warning'"
      class="text-center"
      width="100%">
    <div>
    The {{role}} role is currently {{ roleHolder ? '':'not' }} being fulfilled by {{ roleHolder ? roleHolder.name :'anyone' }}
      <div v-if="roleHolder">{{ roleHolder.mobile ? '('+roleHolder.mobile+')' :''}}</div>
    </div>
  </v-alert>
</template>

<script>
import { BookingsHttp } from "@/http/BookingsHttp";

export default {
name: "CurrentRoleHolder",
  props:{
  loading:{
    type: Boolean
  },
    role:{
      type: String
    }
  },
  data: () => ({ roleHolder: null}),
  methods: {
    pollData () {
      this.polling = setInterval(async () => {
        this.roleHolder = await BookingsHttp.getCurrentRoleHolder(this.type)
      }, 60000)
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  async created () {
    this.roleHolder = await BookingsHttp.getCurrentRoleHolder(this.type)
    this.pollData()
  }
}
</script>