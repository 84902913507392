<template>
  <div v-if="sunrise_sunset">
    EGDM
    <v-icon left>mdi-weather-sunset-up</v-icon>
    Sunrise:{{ sunrise_sunset.local.sunrise }}
    <v-icon left>mdi-weather-sunset-down</v-icon>
    Sunset: {{ sunrise_sunset.local.sunset }}
  </div>
</template>

<script>
import {CheckWxApiHttp} from "@/http/CheckWxApiHttp";

export default {
  name: "SunTimes",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sunrise_sunset: null,
      timezone: null
    }
  },
  async mounted() {
    await CheckWxApiHttp.getSunTimes('EGDM').then(
        response => {
          this.sunrise_sunset = response.data.data[0].sunrise_sunset
          this.timezone = response.data.data[0].timezone
        }
    )
  }
}
</script>

<style scoped>

</style>