/**
 * Gets the Sorite Status using Business Logic
 * @param sortie
 */

export function getSortieStatus(sortie) {
    let arrivalTime = +""
    let overdueTime = +""
    // // Get time Now
    const TimezoneOffset = new Date().getTimezoneOffset() * 60
    const nowUTC = Math.floor((new Date()).getTime() / 1000) + TimezoneOffset

    const departureTime = sortie.eTD.utcTimestamp
    const suttoTime = sortie.eTD.utcSUTTOTimestamp
    if (sortie.eTA) {
        arrivalTime = sortie.eTA.utcTimestamp
        overdueTime = sortie.eTA.overdueUTCTimestamp
    }
    // const nowUTC = moment().unix() - (moment().parseZone().utcOffset() * 60) // UTC now

    if (sortie.captainDeclaration) {
        return "complete"
    }
    if (sortie.isCancelled) {
        return "cancelled";
    }

    if (!sortie.isCancelled && !sortie.hasWalked) {
        return nowUTC > departureTime ? "delayed departure" : "scheduled";
    }

    if (sortie.hasWalked && suttoTime > nowUTC) {
        return "walking to aircraft";
    }

    if (sortie.hasWalked && departureTime > nowUTC && nowUTC > suttoTime) {
        return "start up and taxi";
    }

    if (sortie.hasWalked && arrivalTime > nowUTC && nowUTC > departureTime) {
        return "airborne";
    }

    if (sortie.hasWalked && overdueTime > nowUTC && nowUTC > arrivalTime) {
        return "awaiting flight details";
    }

    if (sortie.hasWalked && nowUTC > overdueTime) {
        return "late";
    }

    return "Error 500"

}


/**
 * Gets an array of valid actions that can be done to the sortie based on the sortie status
 * @param status
 */
export function getListOfAvailableSortieActions(sortieStatus, availableActions) {
    return availableActions.filter(a => a.validFlightStatus.includes(sortieStatus) || a.validFlightStatus.includes('*'))
        .map(a => a.action)
}