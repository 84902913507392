export class CheckWxApiHttp {

    /**
     * Get RAW METAR.
     * @param sites
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getMETARRaw(sites) {
        try {

            return await window.axios.get(`checkwx/metar/${sites}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get RAW & Decode METAR.
     * @param sites
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getMETARDecoded(sites) {
        try {

            return await window.axios.get(`checkwx/metar/${sites}/decoded`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get METAR Nearest Airfield
     * @param lat
     * @param long
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getNearestMETAR(lat,long) {
        try {

            return await window.axios.get(`checkwx/metar/${lat}/${long}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get RAW TAF.
     * @param sites
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getTAFRaw(sites) {
        try {

            return await window.axios.get(`checkwx//taf/${sites}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get RAW & Decode TAF.
     * @param sites
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getTAFDecoded(sites) {
        try {

            return await window.axios.get(`checkwx//taf/${sites}/decoded`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get TAF Nearest Airfield
     * @param lat
     * @param long
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getNearestTAF(lat,long) {
        try {

            return await window.axios.get(`checkwx/taf/${lat}/${long}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get Sun Times for Station
     * @param sites
     * @returns {Promise<AxiosResponse<any>>}
     */

    static async getSunTimes(sites) {
        try {
            return await window.axios.get(`checkwx/${sites}/suntimes`)
        } catch (err) {
            return Promise.reject(err);
        }
    }

}