<template>
  <v-container class="">
    <v-layout row wrap align-content-center>
      <v-flex xs8 class="pa-2">
        <v-switch
            v-model="autoUpdate"
            :label="autoUpdate ? `Update in ${formattedTimeLeft}` : 'Auto Update'"
        />
      </v-flex>
      <v-flex xs4 class="pa-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <app-btn small fab color="primary" class="mt-2" v-bind="attrs"
                     v-on="on"
                     @click="onTimesUp">
              <v-icon>mdi-refresh</v-icon>
            </app-btn>
          </template>
          <span>Refresh now</span>
        </v-tooltip>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import AppBtn from "@/components/app/Btn";

const TIME_LIMIT = 60;

export default {
  name: "RefreshSwitch",
  components: {AppBtn},
  data() {
    return {
      autoUpdate: false,
      refreshInterval: 30,
      timePassed: 0,
      timerInterval: null
    };
  },

  computed: {
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },

  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    autoUpdate(newValue) {
      if (newValue) {
        this.startTimer();
      } else {
        this.resetTimer();
      }
    }
  },

  methods: {
    onTimesUp() {
      this.$emit('refresh')
      this.timePassed = 0
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    resetTimer() {
      clearInterval(this.timerInterval);
      this.timePassed = 0
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
};
</script>

<style scoped lang="scss">

</style>
