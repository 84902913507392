<template>
  <v-sheet rounded="lg" class="">
    <calendar :items="events"
              :weekday="[1, 2, 3, 4, 5, 6, 0]"
              :scoped-groups="[]"
              :categories="[]"
              :selected-date="localSelectedDate"
              mode="stack"
              :calendar-day-start-time-minutes="420"
              :calendar-day-end-time-minutes="1320"
              :calendar-day-interval-minutes="30"
              v-on:updateSelectedDate="localSelectedDate=$event"
    >
    </calendar>
  </v-sheet>
</template>

<script>

import Calendar from "@/components/calendar/Calendar";
export default {
  name: "FlightCalendar",
  components: {Calendar},
  props: {
    events: {
      type: Array
    },
    bookingCategories: {
      type: Array
    },
    loading: {
      type: Boolean
    },
  },
  data: () => ({
    value: '',
    ready: false,
    localSelectedDate:''
  }),
  computed:{
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    earliestTime() {
      return 9
    },
    latestTime() {
      return 22
    },
    intervalCount() {
      return this.latestTime - this.earliestTime + 1
    }
  },
  mounted () {
    // this.ready = true
    // this.scrollToTime()
    // this.updateTime()
  },
  methods: {
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
  },
}
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>

