<template>
  <div class="text-body-2">
    <v-chip small :class="`${status} white--text caption text-capitalize`">{{ status }}</v-chip>
  </div>
</template>

<script>
export default {
  name: "FlightStripStatusChip",
  props: {
    status: {
      type: String
    }
  }
}
</script>

<style scoped>
.v-chip.late {
  background: tomato;
}

.v-chip.complete {
  background: mediumpurple;
}

.v-chip.awaiting.flight.details {
  background: orange;
}

.v-chip.airborne {
  background: blue;
}

.v-chip.start.up.and.taxi {
  background: blue;
}

.v-chip.walking.to.aircraft {
  background: blue;
}

.v-chip.delayed.departure {
  background: mediumorchid;
}

.v-chip.scheduled {
  background: orange;
}
</style>