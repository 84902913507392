<template>
  <div>
    <v-sheet rounded="lg" class="background text-body-2 font-weight-light text-right primary my-2" min-height="20px">
      <v-col class="text-right">
        <refresh-switch v-on:refresh="refreshSorties"/>
      </v-col>
    </v-sheet>
    <sun-times :loading="this.loading"/>
    <current-role-holder role="Duty Officer" class="mb-3"/>
    <integrated-working-position class="mb-5 mx-1" :loading="this.loading" :last-updated="lastUpdated"
                                 :search-group-ids="searchGroupIds" :search-start-date="searchStartDate"
                                 :search-end-date="searchEndDate"
                                 :scoped-groups="scopedGroups"
                                 v-on:changeSearchStartDate="changeSearchStartDate($event)"
                                 v-on:changeSearchEndDate="changeSearchEndDate($event)"
                                 v-on:changeSearchGroupIds="changeSearchGroupIds($event)"
                                 v-on:search="refreshSorties"


    />
  </div>
</template>

<script>
import CurrentRoleHolder from "@/components/shared/CurrentRoleHolder";
import IntegratedWorkingPosition from "@/components/shared/IntegratedWorkingPosition";
import {mapActions, mapState} from "vuex";
import SunTimes from "@/components/widgets/SunTimes";
import RefreshSwitch from "@/components/widgets/RefreshSwitch";
import {addMonths, subDays} from "date-fns"

export default {
  name: "FlyingOpsDashboard",
  components: {RefreshSwitch, SunTimes, IntegratedWorkingPosition, CurrentRoleHolder},
  computed: {
    ...mapState("app", ["loading", "loggedInUser"]),
    ...mapState("sorties", ["sorties"]),
    ...mapState("group", ["groups"]),
    ...mapState("user", ["groupMembership"]),

    activeGroupDetails() {
      return this.groups.find(g => g._id == this.activeGroup)
    },
    sortieSearchQuery() {
      return {
        "groupIds": this.searchGroupIds,
        "startDate": this.searchStartDate,
        "endDate": this.searchEndDate
      }
    },
    scopedGroupIds() {
      return this.loggedInUser ? this.groupMembership.map(g => g._id) : []
    },
    scopedGroups() {
      return this.loggedInUser ? this.groupMembership : []
    }

  },
  watch: {
    async sortieSearchQuery(newValue) {
      await this.setSortieSearchQueryParams(newValue)
    }
  },
  data() {
    return {
      polling: null,
      lastUpdated: null,
      activeGroup: null,
      searchGroupIds: [],
      searchStartDate: '',
      searchEndDate: ''
    }
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("sorties", ["setSorties", "searchSortiesByQuery", "setSortieSearchQueryParams"]),
    async refreshSorties() {
      if (this.loggedInUser._id) {
        await this.setLoading(true)
        // await this.setSorties(this.activeGroupDetails)
        await this.searchSortiesByQuery(this.sortieSearchQuery)
        this.lastUpdated = new Date()
        await this.setLoading(false)
      }
    },
    changeSearchGroupIds(arg) {
      this.searchGroupIds = arg
    },

    changeSearchStartDate(arg) {
      this.searchStartDate = arg
    },
    changeSearchEndDate(arg) {
      this.searchEndDate = arg
    }
  },
  async mounted() {
    // Initial load of Vuex data
    this.searchGroupIds = this.scopedGroupIds
    const today = new Date()
    this.searchStartDate =  subDays(today, 0).toISOString().substring(0, 10)
        this.searchEndDate = addMonths(today, 3).toISOString().substring(0, 10)
        await this.refreshSorties()
    // await this.pollData()
    // Setup list of items to fetch on a regular interval
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },
}
</script>

<style scoped>

</style>