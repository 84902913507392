import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,[(_vm.sortie.sortieStatus !== 'complete' && _vm.canUseActionMenu)?_c(VMenu,{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"bottom-left","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},{ ...onMenu, ...onTooltip }),[_c(VIcon,{attrs:{"size":"25"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("Actions")])])]}}],null,false,1630160416)},[_c(VSheet,{attrs:{"rounded":""}},[(_vm.sortie.sortieStatus !== 'complete' && _vm.canUseActionMenu)?_c(VList,{attrs:{"dense":"","tile":false,"nav":""}},[_c(VListItem,[_c(VListItemContent,[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Flight strip actions")])])],1),_vm._l((_vm.actionsList),function(action,i){return _c(VListItemGroup,{key:i},[(_vm.sortie.availableActions.includes(action.action))?_c(VListItem,{attrs:{"two-line":"","disabled":!_vm.scopedCanFly && action.canFlyStatusApplied},on:{"click":function($event){return _vm.openModal(action.modal,_vm.sortie)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(action.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(action.title)+" "+_vm._s(action.canFlyStatusApplied ? _vm.scopedCanFly ? '' : '(disabled)' : '')+" ")]),(!_vm.scopedCanFly && action.canFlyStatusApplied)?_c(VListItemSubtitle,[_vm._v("Check notification bell ")]):_vm._e()],1)],1):_vm._e()],1)})],2):_vm._e()],1)],1):_c('div',[_c(VListItem,{on:{"click":function($event){return _vm.openModal('ViewSortieModal',_vm.sortie)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-eye")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" View ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }